import React, { Component } from "react";
import moment from "moment";
import 'moment-timezone';
import { CircularProgress, Tooltip, Button } from "@material-ui/core";

class DashboardTotal extends Component {
  constructor(props) {
    super(props);
    let today = new Date();
    this.state = {
      loading: true,
      date: moment(today).format("MMMM DD, Y"),
      dashboardData: {}
    };
    this.getDashboardInfo = this.getDashboardInfo.bind(this);
    this.getFreshDashboardInfo = this.getFreshDashboardInfo.bind(this);
    this.getDashboardInfo();
  }

  getDashboardInfo() {
    fetch('https://stg.api.neoride.com/api/parking/v1/ChartKPISummaryGoRoutine', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      }
    })
    .then(response => response.json())
    .then(resp => {
      if (resp) {
        this.setState({ loading: false, dashboardData: resp });
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  getFreshDashboardInfo() {
    this.setState({ loading: true }); // Show loading spinner
    fetch('https://stg.api.neoride.com/api/parking/v1/ChartKPISummary', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      }
    })
    .then(response => response.json())
    .then(resp => {
      if (resp) {
        this.setState({ loading: false, dashboardData: resp });
      }
    })
    .catch(err => {
      console.log(err);
      this.setState({ loading: false }); // Hide loading spinner in case of error
    });
  }

  render() {
    const { lastRefreshedAt, ...dashboardData } = this.state.dashboardData;
    const userTimezone = moment.tz.guess();
    const formattedDate = lastRefreshedAt ? moment.utc(lastRefreshedAt).tz(userTimezone).format("MMMM DD, YYYY [at] HH:mm:ss z") : '';

    return (
      <div className="container-fluid" style={{ padding: '20px' }}>
        <div className="row card">
          <div className="col-md-12">
            <div className="row title-dashboard">
              <div className="col-12">
                <div><b>Last Updated On</b> | {formattedDate}</div>
                <Button 
                  variant="contained" 
                  style={{ backgroundColor: '#008299', color: '#fff', marginTop: '10px', marginBottom: '20px' }} 
                  onClick={this.getFreshDashboardInfo}
                >
                  Refresh Data
                </Button>

                <br />
              </div>
            </div>
            <TotalInfo loading={this.state.loading} dashboardData={dashboardData} />
          </div>
        </div>
      </div>
    );
  }
}

class TotalInfo extends Component {
  render() {
    let dashboardData = this.props.dashboardData;
    if (this.props.loading === true) {
      return <CircularProgress />;
    } else {
      return (
        <div className="row" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {Object.keys(dashboardData).map((item, i) => {
            let id = "dsh" + i;
            let total = dashboardData[item];
            if (['topUp', 'autoTopUp', 'membership', 'tolls', 'refunds'].includes(item)) {
              total = '$' + Number(total).toLocaleString();
            }

            let displayName = item;
            if (item === "topUp") {
              displayName = "Total Top-Ups";
            } else if (item === "autoTopUp") {
              displayName = "Auto Top-Ups";
            } else if (item === "membership") {
              displayName = "Membership Revenue";
            } else if (item === "tolls") {
              displayName = "Toll Charges";
            } else if (item === "refunds") {
              displayName = "Total Refunds";
            } else if (item === "totalUsers") {
              displayName = "Total Users";
            } else if (item === "activeUsers") {
              displayName = "Active Users";
            } else if (item === "usersWithAddressNoSubscription") {
              displayName = "Users with Address, No Subscription";
            } else if (item === "usersWithPaymentMethodNoSubscription") {
              displayName = "Users with Payment Method, No Subscription";
            }

            const tooltips = {
              "topUp": "Total Revenue From Top-Up.",
              "autoTopUp": "Total Revenue From Auto Top-Up.",
              "membership": "Total Revenue From Memberships.",
              "tolls": "Total Charges From Tolls.",
              "refunds": "Total Amount Refunded To Users.",
              "totalUsers": "Total number of registered users.",
              "activeUsers": "Total number of active users.",
              "usersWithAddressNoSubscription": "Users who have entered an address but have no subscription.",
              "usersWithPaymentMethodNoSubscription": "Users who have added a payment method but have no subscription."
            };

            return (
              <div className="content-center col-6 col-md-4" key={id} style={{ marginBottom: '20px' }}>
                <Tooltip title={tooltips[item] || ''}>
                  <div style={{ textAlign: 'center' }}>
                    <h2 style={{ textTransform: 'capitalize', fontSize: '1.2em' }}>{displayName}</h2>
                    <h3 style={{ fontSize: '1.5em', fontWeight: 'bold' }}>{total}</h3>
                  </div>
                </Tooltip>
              </div>
            );
          })}
        </div>
      );
    }
  }
}

export default DashboardTotal;
