import React, {Component} from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom";
import Api from "../../../libraries/api";
import {toast, ToastContainer} from "react-toastify";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TicketLists from "../../presentational/ticket-list";
import TransactionList from "../../presentational/transaction-list";
import moment from "moment";
import _ from "lodash";
import UpdateAccount from "./update-account";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import ResendOtp from "./resend-otp";
import noVehicle from "../../../images/no_vehicle.png"
import noPlate from "../../../images/no_plate.png"
import {SiVisa,SiAmericanexpress,SiMastercard} from 'react-icons/si'
import {FiAlertTriangle} from 'react-icons/fi'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AuthHelper from "../../../libraries/auth-helper";
import { history } from "../../../shared/configure-store";



toast.configure({
    autoClose: 2000,
    draggable: false,
});

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

class AccountDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingZipcode: false,
            errors: {},
            search: "",

            showDetail: false,
            showForm: false,
            showFormEdit: false,
            showPassword: false,
            showEdit: false,

            value: 0,
            rows: [],
            rowsActivity: [],

            id: "",
            name: "",
            accountNumber: "",
            balance: "",
            contact: [],
            address: "",
            loginType: "",
            loginSince: "",
            accountCreated: "",

            payments: [],
            vehicles: [],
            transactions: [],
            bookings: [],
            bookingsSortField: "createdAt",
            bookingsSortDir: "DESC",
            showAuthorization:false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleShowAuthorization  = this.handleShowAuthorization.bind(this)
        this.showMessage = this.showMessage.bind(this)
    }

    componentDidMount() {
        this.__fetchData(false);
    }

    __fetchData = (update) => {
        let id = this.props.match.params.id;

        let route = "/accounts/" + id;
        let route_payment = "/accounts/" + id + "/payments";
        let route_vehicle = "/accounts/" + id + "/vehicles";
        let route_activity = "/accounts/" + id + "/activity";
        let route_booking = "/booking-history/" + id

        Api.get(route)
            .then((resp) => {
                if (resp.data) {
                    let data = resp.data.users;
                    this.setState({
                        id: data.id,
                        name: data.name,
                        accountNumber: data.accountNumber,
                        balance: data.balance,
                        tollTag: data.tollTag,
                        contact: data.contact,
                        address: data.address,
                        stateName : data.stateName,
                        city: data.city,
                        postalCode: data.postalCode,
                        loginType: data.loginType,
                        loginSince: data.loginSince,
                        accountCreated: data.accountCreated,
                        accountVersion: data.accountVersion,
                        status: data.status,
                        credits: data.credits
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });

        Api.get(route_payment)
            .then((resp) => {
                if (resp.data) {
                    this.setState({
                        payments: resp.data.payments,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });

        Api.get(route_vehicle)
            .then((resp) => {
                if (resp.data) {
                    this.setState({
                        vehicles: resp.data.vehicles,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });

        Api.get(route_activity)
            .then((resp) => {
                if (resp.data.activity) {
                    let act = _.orderBy(
                        resp.data.activity,
                        (o) => {
                            return moment(o.Created);
                        },
                        ["desc"]
                    );

                    this.setState({
                        loading: false,
                        rowsActivity: act,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
        Api.get(route_booking, true, "parking")
            .then((resp) => {
                if (resp.data) {
                    let act = _.orderBy(
                        resp.data,
                        (o) => {
                            return moment(o.createdAt);
                        },
                        ["desc"]
                    );

                    this.setState({
                        loading: false,
                        bookings: act,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });

    };

    handleChange(e, newValue) {
        this.setState({
            value: newValue,
        });
    }
    
    

    handleBookingSort = (field) => {
        let bookingsSortField = field;
        let isAsc = this.state.bookingsSortField === field && this.state.bookingsSortDir === "DESC";
        let bookingsSortDir = isAsc ? "ASC" : "DESC";
        // Extract `bookings` from `this.state`
        const bookings = this.state.bookings.slice();
        let type;
        // add any desired type for fields
        switch (bookingsSortField) {
            case "finalPrice":
                type = "numeric";
                break;
            case "startTime":
            case "endTime":
            case "cancelledAt":
            case "createdAt":
                type = "date";
                break;
            default:
                type = "string"
        }
        // perform common sorting (lodash was an overkill for this)
        bookings.sort((o1, o2) => {
            let v1 = o1[bookingsSortField];
            let v2 = o2[bookingsSortField];
            if (type === "numeric") {
              v1 = parseFloat(v1);
              v2 = parseFloat(v2);
            } else if (type === "date") {
              v1 = moment(v1);
              v2 = moment(v2);
            }
            let result;
            if (bookingsSortDir === "ASC") {
                result = (v1 > v2) ? 1 : ((v2 > v1) ? -1 : 0);
            } else {
                result = (v1 > v2) ? -1 : ((v2 > v1) ? 1 : 0);
            }
            return result;
        })
        this.setState({
            bookingsSortField,
            bookingsSortDir,
            bookings
        });
    };
      
    showMessage = (status, message) => {
        if (status) {
            toast.success(  
                <div className="" style={{textAlign:'center'}}> 
                    <h6 style={{marginLeft:'15px'}}>{message}</h6>
                    <div className="col-12" style={{textAlign:'center'}}>
                        <button className="btn btn-white" onClick={()=>history.goBack()}>Accept</button>
                    </div>
                </div>, {
                position: toast.POSITION.TOP_CENTER,
                autoClose:false,
                closeButton:false,
                hideProgressBar:true,
                theme:"light",
            });
        } else {
            toast.error(message, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState(
            {
                rows: [],
                currentPage: newPage,
                currentPageTable: newPage,
                loading: true,
            },
            () => {
                this.__fetchData(true);
            }
        );
    };

    handleChangeRowsPerPage = (event) => {
        this.setState(
            {
                perPage: parseInt(event.target.value, 10),
                currentPage: 1,
                loading: true,
            },
            () => {
                this.__fetchData(false);
            }
        );
    };

    handleShowAuthorization(){
        this.setState({showAuthorization:!this.state.showAuthorization})
    }

    render() {
        return (
            <div  style={{padding:'0px',marginLeft:'-20px',marginRight:'-20px'}}>
                <div className="">
                    <AppBar position="" color="default" >
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className='text-title-big' style={{marginBottom:'20px'}}>{this.state.name}</h2>
                            </div>
                            <div className="col-md-3">
                                <p className="mb-0"></p>
                            </div>
                            <div className="col-md-3 text-right">
                                <Link to="/accounts" className="btn btn-blue-trans">
                                    Back to list
                                </Link>
                            </div>
                        </div>
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Profile" {...a11yProps(0)} />
                            <Tab label="Vehicle" {...a11yProps(1)} />
                            <Tab label="Payment" {...a11yProps(2)} />
                            <Tab label="Settings" {...a11yProps(3)} />
                            <Tab label="Tickets" {...a11yProps(4)} />
                            <Tab label="Transactions" {...a11yProps(5)} />
                            <Tab label="Activity" {...a11yProps(6)} />
                            <Tab label="Bookings" {...a11yProps(7)} />
                        </Tabs>
                    </AppBar>

                    <TabPanel value={this.state.value} index={0}>
                        <div className="row" >
                            <div className="col-md-6" style={{padding:'10px'}}>
                                <div className="card">
                                    <label className='text-title'>Profile Information</label>
                                    <div className="row">
                                        <h5 className="col-4 text-black bold ">Name</h5>
                                        <p className="col-8 pl-2 pl-lg-3">{this.state.name}</p>
                                    </div>
                                    <div className="row" style={{marginTop:'5px'}}>
                                        <label className="col-4 text-black bold" >Balance account</label>
                                        <p className="col-8 pl-2 pl-lg-3">${this.state.balance}</p>
                                    </div>
                                    <div className="row" >
                                        <label className="col-4 text-black bold" >Toll Tag ID</label>
                                        <h5 className="col-8 pl-2 pl-lg-3">{this.state.tollTag}</h5>
                                    </div>
                                    <div className="row" style={{marginTop:'5px'}}>
                                        <h5 className="col-4 text-black bold" >Contact</h5>
                                        <div className="col-5" style={{paddingLeft:'0px'}}>
                                            {this.state.contact.map((item, index) => {
                                                return (
                                                    <p key={index} className=" pl-2 pl-lg-3 mb-0">
                                                        {item}
                                                    </p>
                                                );
                                            })}
                                        </div>
                                        <div className="col-3" style={{padding:'0px 5px',textAlign:'right'}}>
                                            <button className=" btn btn-blue-trans" style={{padding:'3px 10px'}}>Add contact</button>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginTop:'5px'}}>
                                        <label className="col-4 text-black bold ">Address </label>
                                        <p className="col-5 ">{this.state.address},{this.state.city} - {this.state.postalCode}</p>
                                        <div className="col-3" style={{padding:'0px 5px',textAlign:'right'}}>
                                            <button className="btn btn-blue-trans" style={{padding:'3px 10px'}}>Add address</button>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginTop:'5px'}}>
                                        <label className="col-4 text-black bold" >State</label>
                                        <p className="col-8 pl-2 pl-lg-3">{this.state.stateName}</p>
                                    </div>
                                    <hr style={{marginTop:'30px'}}/>
                                    <Fulfillment userId={this.state.accountNumber}/>
                                </div>
                            </div>
                            <div className="col-md-6" style={{padding:'10px'}}>
                                <div className=" card">
                                    <label className='text-title'>Account Information</label>
                                    <div className="row">
                                        <h5 className="col-4 text-black bold ">Account number</h5>
                                        <p className="col-8 pl-2 pl-lg-3">{this.state.accountNumber}</p>
                                    </div>
                                    <div className="row">
                                        <label className="col-4 text-black bold" style={{marginTop:'0px'}}>Account created</label>
                                        <p className="col-8 pl-2 pl-lg-3">{this.state.accountCreated}</p>
                                    </div>
                                    <div className="row">
                                        <label className="col-4 text-black bold">Flagged status</label>
                                        <p className="col-5 pl-2 pl-lg-3">description in more detail</p>
                                        <div className="col-3" style={{padding:'0px 0px'}}>
                                            <button className="btn btn-blue-trans mb-3">Clear flag</button>
                                        </div>
                                    </div>
                                    <UpdateAccount accountVersion={this.state.accountVersion}
                                                accountStatus={this.state.status} credits={this.state.credits}
                                                userId={this.state.accountNumber}
                                                fullName={this.state.name}/>
                                </div>
                            </div>
                            <div className="col-md-6" style={{padding:'10px'}}>
                                <div className="card ">
                                    <label className='text-title'>Log History</label>
                                    <div className="row">      
                                        <label className="col-4 text-black bold ">Login type</label>
                                        <p className="col-8 pl-2 pl-lg-3">{this.state.loginType}</p>
                                    </div>
                                    <div className="row">
                                        <label className="col-4 text-black bold ">Logged in since</label>
                                        <p className="col-8 pl-2 pl-lg-3">{this.state.loginSince}</p>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-4">
                                            <button className="btn btn-blue-trans mb-2">
                                                Force Logout
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <ResendOtp newUser={false} userOtpContact={this.state.contact[0]}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{padding:'10px'}}>
                                {this.state.status === 'active' ?
                                <div className="card ">
                                    <label className='text-title'>Delete Account</label>
                                    <div className="row">
                                        <div className="col-1" >
                                            <FiAlertTriangle style={{fontSize:'40px',color:'#ff2323'}}/>
                                        </div>
                                        <label className="col-8 text-black bold " style={{marginTop:'0px'}}>Permanently delete the account and all data associated with it.</label>
                                    </div>
                                    <hr style={{marginTop:'20px'}}/>
                                    <div className="row">
                                        <div className="col-4">
                                            <button className="btn btn-red-outline" onClick={()=>this.handleShowAuthorization()}>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :('')
                                }
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        <div className="row" >
                            {this.state.vehicles.length ? (
                                this.state.vehicles.map((item, index) => {
                                    return (
                                        <div className="col-md-6" style={{padding:'10px'}}>
                                            <div className="card" key={index} >
                                                <label className="title">Vehicle {index + 1}</label>
                                                <p className="pl-2 pl-lg-3">
                                                    <div className="row">
                                                        <div className="col-6" >
                                                            <div className="content-center">
                                                            {item.patch === '' || item.patch === "data:image/jpeg;base64,"?
                                                                <img
                                                                    src={noPlate}
                                                                    alt="no vehicle"
                                                                    width="200"
                                                                    height="70"
                                                                />
                                                            :
                                                                <img
                                                                    src={item.patch}
                                                                    alt="Red dot"
                                                                    width="200"
                                                                    height="70"
                                                                />
                                                            }
                                                            </div>
                                                            <div className="content-center" style={{marginTop:'20px'}}>
                                                            {item.overview === '' || item.overview === "data:image/jpeg;base64,"?
                                                                <img
                                                                    src={noVehicle}
                                                                    alt="no vehicle"
                                                                    width="250"
                                                                    height="250"
                                                                />
                                                                :
                                                                <img
                                                                    src={item.overview}
                                                                    alt={"Red dot"}
                                                                    width="250"
                                                                    height="250"
                                                                />
                                                            }
                                                            </div>
                                                        </div>   
                                                        <div className="col-6">
                                                            <div className="row" style={{marginTop:'20px'}}>
                                                                <label className="title col-5">License:</label>
                                                                <p className="title col-7" style={{margin:'0px'}}> {item.licensePlateNumber} </p>
                                                            </div>
                                                            <div className="row" style={{marginTop:'20px'}}>
                                                                <label className="title col-5">Vehicle type:</label>
                                                                <p className="title col-7" style={{ margin: '0px' }}>
                                                                    {item.isRental === 0 || item.isRental === '' ? 'Personal' : 'Rental'}
                                                                </p>
                                                            </div>
                                                             <div className="row" style={{marginTop:'20px'}}>

                                                                <label className="title col-5">Nick Name:</label>
                                                                <p className="title col-7" style={{margin:'0px'}}>{item.nickName}</p>
                                                            </div>
                                                            <div className="row" style={{marginTop:'20px'}}>
                                                                <label className="title col-5">Type:</label>
                                                                <p className="title col-7" style={{margin:'0px'}}>{item.type.charAt(0).toUpperCase() + item.type.slice(1)}{" "}</p>
                                                            </div>
                                                            <div className="row" style={{marginTop:'20px'}}>
                                                                <label className="title col-5">State:</label>
                                                                <p className="title col-7" style={{margin:'0px'}}>{item.licensePlateState} </p>
                                                            </div>
                                                            <div className="row" style={{marginTop:'20px'}}>
                                                                <label className="title col-5">Make:</label>
                                                                <p className="title col-7" style={{margin:'0px'}}>{item.make.charAt(0).toUpperCase() + item.make.slice(1)}{" "}</p>
                                                            </div>
                                                            <div className="row" style={{marginTop:'20px'}}>
                                                                <label className="title col-5">Model:</label>
                                                                <p className="title col-7" style={{margin:'0px'}}>{item.model}{" "}</p>
                                                            </div>
                                                        
                                                            <div className="row" style={{marginTop:'20px'}}>
                                                                <label className="title col-5">Year:</label>
                                                                <p className="title col-7" style={{margin:'0px'}}>{item.year}{" "}</p>
                                                            </div>
                                                            <div className="row" style={{marginTop:'20px'}}>
                                                                <label className="title col-5">Fuel type:</label>
                                                                <p className="title col-7" style={{margin:'0px'}}>{item.fuelType}{" "}</p>
                                                            </div>
                                                            <div className="row" style={{marginTop:'20px'}}>
                                                                <label className="title col-5">Color:</label>
                                                                <p className="title col-7" style={{margin:'0px'}}>{item.color.charAt(0).toUpperCase() + item.color.slice(1)}{" "}</p>
                                                            </div>
                                                            <div className="row" style={{marginTop:'20px'}}>
                                                                <label className="title col-5">Subscribed:</label>
                                                                <p className="title col-7" style={{margin:'0px'}}>{item.subscribed}{" "}</p>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '20px' }}>
                                                                  <label className="title col-5">createdAt:</label>
                                                                  <p className="title col-7" style={{ margin: '0px' }}>
                                                                    {new Date(item.createdAt).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })}{" "}
                                                                 </p>
                                                             </div>
                                                    
                                                        </div> 
                                                    </div>
                                                    
                                                </p>
                                            
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="col-md-4">
                                    <label className="title">No Vehicle</label>
                                </div>
                            )}
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={2}>
                        <div className="row">
                            {this.state.payments.length ? (
                                <>
                                    {this.state.payments.map((item, index) => {
                                        return (
                                            <div className="col-md-6  col-lg-4" style={{padding:'10px'}}>
                                                <div className="card " key={index}>
                                                    <div className="content-center">
                                                        <div className="credit-card col-9 " style={{height:'130px'}}>
                                                            <div className="content-center" style={{marginTop:'40px'}}>
                                                                <h5 className="text-white" style={{fontSize:'28px'}}>{item.card === "" ? "" : '••••   ••••   ••••   '}
                                                                    <span className="text-white num18"> {item.card.slice(item.card.length-4, item.card.length)}</span>
                                                                </h5>
                                                            </div>
                                                            <div className="content-center col-12" style={{marginTop:'20px',}}>
                                                                <h5 className="col-10 text-white" style={{paddingLeft:'0px'}}>
                                                                    {item.expires === "" ? ""
                                                                    : "Expires " + item.expires}{" "}
                                                                </h5>
                                                                <div className="col-2" style={{textAlign:'end',marginTop:'-5px',paddingRight:'0px'}}>
                                                                {item.paymentType ? 
                                                                    item.paymentType === 'visa' ? 
                                                                    <SiVisa style={{fontSize:'30px', color:'white'}}/>
                                                                    :item.paymentType === 'amex' ? 
                                                                    <SiAmericanexpress style={{fontSize:'30px', color:'white'}}/> 
                                                                    :<SiMastercard style={{fontSize:'30px', color:'white'}}/>
                                                                : item.paymentMethodName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*<label className="title">
                                                        {item.paymentType
                                                            ? item.paymentType
                                                            : item.paymentMethodName}
                                                    </label>*/}
                                                    <p className="pl-2 pl-lg-3">
                                                    {/*  {item.card === "" ? "" : "Card Number " + item.card}{" "}
                                                        <br/>
                                                        {item.expires === ""
                                                            ? ""
                                                            : "Expires " + item.expires}{" "}
                                                        <br/>
                                                        */}
                                                        {item.billingAddress && (
                                                            <>
                                                                <br/>
                                                                <strong>Billing Address:</strong>
                                                                <br/>
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item.billingAddress,
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </p>
                                                    <p className="pl-2 pl-lg-3 text-blue">
                                                        {item.primary === 1 ? "• primary" : ""}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className="col-12">
                                        <button className="btn btn-blue-trans mb-2">
                                            Add payment method
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div className="col-md-4">
                                    <label className="title">No Payment</label>
                                </div>
                            )}
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={3}>
                        <div className="">
                            <div className="card col-md-8">
                                <label className="title">Permissions </label>

                                <div className="row">
                                    <div className="col-4">
                                        <p className="pl-2 pl-lg-3">Comment about</p>
                                    </div>
                                    <div className="col-4">
                                        <p>Denied</p>
                                        <p>Granted</p>
                                    </div>
                                    <div className="col-3">
                                        <button className = "btn btn-blue-trans " >
                                            Send request
                                        </button>
                                        <button className = "btn btn-blue-trans "style={{marginTop:'10px'}}>
                                            Send request
                                        </button>
                                    </div>
                                </div>
                                <label className="title">Diagnostics</label>
                                <p className="pl-2 pl-lg-3">App version Device etc.</p>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={4}>
                        <div className="row">
                            <div className="col-12">
                                <label className="title">
                                </label>
                            </div>
                        </div>
                        <TicketLists user={this.props.match.params.id}/>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={5}>
                        <div >
                            <TransactionList user={this.props.match.params.id}/>
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={6}>
                        <div className="">
                            <div className=" card col-12">
                                {/*<label className="title">Account activity </label>*/}
                                <div className="table-wrapper">
                                    <Table className="table-list mt-3" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Event ID</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Created</TableCell>
                                                <TableCell>Event Description</TableCell>
                                                <TableCell>By</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={8}
                                                        align="center"
                                                        className="py-5"
                                                    >
                                                        <CircularProgress color="primary"/>
                                                    </TableCell>
                                                </TableRow>
                                            ) : this.state.rowsActivity.length === 0 ? (
                                                <TableRow style={{height: 33 * 10}}>
                                                    <TableCell
                                                        colSpan={8}
                                                        style={{textAlign: "center"}}
                                                    >
                                                        No activity
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.rowsActivity.map((row, index) => {
                                                    return (
                                                        <TableRow key={index} selected={row.selected}>
                                                            <TableCell>
                                                                {row.EventId === null ? "NA" : row.EventId}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.Status === null ? "NA" : row.Status}
                                                            </TableCell>
                                                            <TableCell>
                                                            {row.Created === null
    ? "NA"
    : moment                                                    (row.Created).format("MM/DD/YYYY, hh:mm:ss A")}
                                                                
    
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.EventDescription === null
                                                                    ? "NA"
                                                                    : row.EventDescription}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.by === null ? "NA" : row.by}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={7}>
                        <div className="">
                            <div className=" card col-12">
                                {/*<label className="title">Account activity </label>*/}
                                <div className="table-wrapper">
                                    <Table className="table-list mt-3" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => this.handleBookingSort('id')}>ID {this.state.bookingsSortField === 'id' ? (this.state.bookingsSortDir === 'ASC' ? '▲' : '▼') : ''} </TableCell>
                                                <TableCell>Booking Id</TableCell>
                                                <TableCell style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => this.handleBookingSort('finalPrice')}>Final Price  {this.state.bookingsSortField === 'finalPrice' ? (this.state.bookingsSortDir === 'ASC' ? '▲' : '▼') : ''}</TableCell>
                                                <TableCell style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => this.handleBookingSort('createdAt')}>Created At  {this.state.bookingsSortField === 'createdAt' ? (this.state.bookingsSortDir === 'ASC' ? '▲' : '▼') : ''}</TableCell>
                                                <TableCell style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => this.handleBookingSort('startTime')}>Start  {this.state.bookingsSortField === 'startTime' ? (this.state.bookingsSortDir === 'ASC' ? '▲' : '▼') : ''}</TableCell>
                                                <TableCell style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => this.handleBookingSort('endTime')}>End  {this.state.bookingsSortField === 'endTime' ? (this.state.bookingsSortDir === 'ASC' ? '▲' : '▼') : ''}</TableCell>
                                                <TableCell style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => this.handleBookingSort('cancelledAt')}>Canceled On  {this.state.bookingsSortField === 'cancelledAt' ? (this.state.bookingsSortDir === 'ASC' ? '▲' : '▼') : ''}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.loading ? (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={8}
                                                        align="center"
                                                        className="py-5"
                                                    >
                                                        <CircularProgress color="primary"/>
                                                    </TableCell>
                                                </TableRow>
                                            ) : this.state.bookings.length === 0 ? (
                                                <TableRow style={{height: 33 * 10}}>
                                                    <TableCell
                                                        colSpan={8}
                                                        style={{textAlign: "center"}}
                                                    >
                                                        No activity
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.bookings.map((row, index) => {
                                                    return (
                                                        <TableRow key={index} selected={row.selected}>
                                                            <TableCell>
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.id}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.bookingId}
                                                            </TableCell>
                                                            <TableCell>
                                                                {parseFloat(row.finalPrice).toFixed(2)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.createdAt === null ? "NA" : moment(row.createdAt).format("MM/DD/YYYY, hh:mm:ss A")}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.startTime === null ? "NA" : moment(row.startTime).format("MM/DD/YYYY, hh:mm:ss A")}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.endTime === null ? "NA" : moment(row.endTime).format("MM/DD/YYYY, hh:mm:ss A")}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.cancelledAt === null ? "NA" : moment(row.cancelledAt).format("MM/DD/YYYY, hh:mm:ss A")}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </TabPanel>                    
                </div>
                <ToastContainer style={{marginTop:'100px'}}  theme="light"/>
                {this.state.showAuthorization ? 
                    <AuthorizationDialog handleShowAuthorization={this.handleShowAuthorization}
                                        userName={this.state.name}
                                        contact={this.state.contact}
                                        userId={this.props.match.params.id}
                                        showMessage = {this.showMessage}
                    />
                :('')
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        permission_state: state.permission_state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(RootActions, dispatch);
};

class Fulfillment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFulfillment: false,
            hasAnOrder: false,
            userId: '',
            accountNumber: '',
            created: '',
            status: 'Not ordered',
            order: '',
            transponderFee: '',
            name: '',
            email: '',
            address: '',
            stateName: '',
            city: '',
            postalCode: '',
            deliveryProvider: '',
            trackingNumber: '',
            purchaseDetail: [],
            purchaseHistory: []
        }
        this.showDetail = this.showDetail.bind(this);
    }

    showDetail() {
        Api.get('/accounts/' + this.props.userId, true,'core').then(resp => {
            if (resp.data) {
                this.setState({
                    userId: resp.data.users.accountNumber,
                    name: resp.data.users.name,
                    email: (resp.data.users.contact[0]) ? resp.data.users.contact[0] : '',
                    address: resp.data.users.address,
                    stateName: resp.data.users.stateName,
                    city: resp.data.users.city,
                    postalCode: resp.data.users.postalCode,
                    deliveryProvider: resp.data.users.deliveryProvider,
                    trackingNumber: resp.data.users.trackingNumber,
                })
            }
        }).catch(err => {
            console.log(err);
        });
        //Get Purchase Data
        Api.get('/purchases?userId=' + this.props.userId, true).then(resp => {
            //Get Detail
            Api.get('/purchase/detail/' + resp.data.Purchases[0]['id'], true).then(respPurchase => {
                this.setState({
                    purchaseDetail: respPurchase.data.Purchase,
                    purchaseHistory: respPurchase.data.purchaseHistory,
                    hasAnOrder: true
                })
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({showFulfillment: true})
    }

    handleClose = e => {
        this.setState(prevState => ({
                showFulfillment: false,
            })
        )
    };

    render() {
        let purchase = this.state.purchaseDetail;
        let purchaseHistory = this.state.purchaseHistory;
        if (this.state.hasAnOrder === false) {
            return (
                <div>
                    <button className="btn btn-blue-trans mb-2" onClick={this.showDetail}>Fulfillment</button>
                    <Dialog
                        maxWidth={'md'}
                        open={this.state.showFulfillment}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                        className="dialog-custom">
                        <DialogTitle id="form-dialog-title">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <span>Order</span>
                                </div>
                            </div>
                            <button onClick={this.handleClose} className="btn-close">
                                <i className="fas fa-times"> </i>
                            </button>
                        </DialogTitle>
                        <DialogContent>
                            The user doesn't has an order.
                        </DialogContent>
                    </Dialog>
                </div>
            )
        }
        if (this.state.hasAnOrder === true) {
            return (
                <div>
                    <button className="btn btn-blue-trans mb-2" onClick={this.showDetail}>Fulfillment</button>
                    <Dialog
                        maxWidth={'xl'}
                        scroll={"body"}
                        open={this.state.showFulfillment}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                        className="dialog-custom">
                        <DialogTitle id="form-dialog-title">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <span>Order</span>
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-0"><label className="title d-inline">Status</label>
                                        {purchase['status'] === 'sent' ?
                                            (<i
                                                className="status text-capitalize"> Shipped</i>) : (<i
                                                className="status text-capitalize"> {purchase['status']}</i>)
                                        }
                                    </p>
                                </div>
                            </div>
                            <button onClick={this.handleClose} className="btn-close">
                                <i className="fas fa-times"> </i>
                            </button>
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="title">Account number</label>
                                        <p className="pl-2 pl-lg-3">{purchase["userId"]}</p>
                                        <Link to={'/accounts/detail/' + purchase["userId"]}
                                              className="btn btn-blue-trans mb-3">Go
                                            to Account</Link>
                                        <label className="title">Contact and Shipping</label>
                                        <p className="pl-2 pl-lg-3">
                                            {purchase["fullName"]}<br/>
                                            {purchase["address"]}<br/>
                                            {purchase["city"]}, {purchase["stateId"]} {purchase["postalCode"]}<br/>
                                            {purchase["email"]}<br/>
                                        </p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="title">Request Date</label>
                                                <p className="pl-2 pl-lg-3">{purchase["created"]}</p>
                                            </div>
                                            {purchase['status'] === 'sent' ?
                                                (
                                                    <div className="col-md-6">
                                                        <label className="title">Shipped Date</label>
                                                        <p className="pl-2 pl-lg-3">{purchase["transponderShippedDate"]}</p>
                                                    </div>
                                                ) : ('')
                                            }
                                            {purchase['status'] === 'delivered' ?
                                                (
                                                    <div className="col-md-6">
                                                        <label className="title">Delivered Date</label>
                                                        <p className="pl-2 pl-lg-3">{purchase["createdAt"]}</p>
                                                    </div>
                                                ) : ('')
                                            }

                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="title">Delivery Provider</label>
                                                <p className="pl-2 pl-lg-3">{purchase["provider"]}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="title">Tracking Id</label>
                                                <p className="pl-2 pl-lg-3">{purchase["trackId"]}</p>
                                            </div>
                                            <div className="col-12">
                                                <label className="title"></label>
                                                <p className="pl-2 pl-lg-3"></p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="title">Toll Tag Id</label>
                                                <p className="pl-2 pl-lg-3">{purchase["transponderShippedId"]}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="title">Toll Tag Fee</label>
                                                <p className="pl-2 pl-lg-3">$0.0</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label>Shipped History</label>
                                <div className="table-wrapper">
                                    <Table className="table-list mt-3" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Toll Tag Id</TableCell>
                                                <TableCell>Delivery Provider</TableCell>
                                                <TableCell>Tracking Id</TableCell>
                                                <TableCell>Shipping Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {purchaseHistory.map(function (item, i) {
                                                return (
                                                    <TableRow key={"history-shipped" + item.id}>
                                                        <TableCell>{item.transponderId}</TableCell>
                                                        <TableCell>{item.provider}</TableCell>
                                                        <TableCell>{item.trackId}</TableCell>
                                                        <TableCell>{item.transponderShippedDate}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>

                    
                </div>
            )
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AccountDetail);


class AuthorizationDialog extends Component{
    constructor(props){
        super(props)
        this.state={
            password:'',
            email:'',
            showPassword:false,
            loading:false,
            errors: {},
        }
    }
    
    handleChange(e,prop){
        this.setState({
            [prop]:e.target.value
        })
    }

    handleAuthorization(){
        let email=AuthHelper.getProfile().email
        this.setState({
            errors: {},
            loading: true,
        }
    );
        let params = {
            email: email,
            password: this.state.password,
        };
        Api.post('/login', params, false).then(resp => {
            if(resp.success){
                this.setState({ loading: false });
                this.deleteAccount()
            }
        }).catch(err => {
            this.setState({
                errors: {messages:'Invalid password try again'},
                loading: false
            });
        })
    }

    deleteAccount(){
        Api.delete('/delete-account/'+this.props.userId,'',true,'onboarding').then(resp=>{
            if(resp.success){
                this.setState({loading: false});
                this.props.handleShowAuthorization()
                this.props.showMessage(true,'Successful, The user was delete')
                //setTimeout(() => { history.push("/accounts"); }, 1000);
            }
        }).catch(err=>{
            this.setState({
                loading: false
            });
            this.props.showMessage(false,'Error: ' + err.error)
        })
    }

    handleClickShowPassword = () => {
        this.setState({
            showPassword:(!this.state.showPassword)
        })
    };

    render(){
        return(
            <div>
                <Dialog  open={true} maxWidth={'md'} 
                    className="dialog-custom"
                >
                <DialogTitle id="form-dialog-title"  >
                        <div className="col-md-12">
                            <h5 className="text-black num18">Do you want to delete the user&nbsp;
                                <span style={{fontSize:'19px',fontWeight:'bolder',color:'#000000CD'}}> 
                                    {this.props.userName === '' ? this.props.contact[0] : this.props.userName}
                                </span>
                                &nbsp;?
                            </h5>
                        </div>
                        <button onClick={()=> this.props.handleShowAuthorization()} className="btn-close">
                            <i className="fas fa-times"> </i>
                        </button>
                    </DialogTitle>
                    <DialogContent style={{overflow:'hidden'}}>
                        <div className="col-12">
                            <div>
                                <h4 className="text-black num15">Authorization required</h4>
                            </div>
                            <div className='content-center' style={{marginTop:'20px'}}>
                                <TextField
                                    className="col-6"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    id="adornment-password"
                                    name="password"
                                    label="Enter your password"
                                    onChange={(e) => this.handleChange(e,'password')}
                                    value={this.state.password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <i className="fas fa-eye"> </i> :
                                                        <i className="fas fa-eye-slash"> </i>}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    fullWidth
                                />
                            </div>
                            <div className='content-center' style={{marginTop:'20px'}}>
                                <div className='text-danger mb-2'>{this.state.errors.messages}</div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions style={{overflow:'hidden'}}>
                        <div className="col-12 content-center">

                        <div className="col-3" style={{padding:'10px 0px'}}>
                            <button className="btn btn-blue-trans"
                                    onClick={()=>this.props.handleShowAuthorization()}
                            >Cancel
                            </button>
                        </div>
                        <div className="col-4" style={{padding:'10px 0px'}}>
                            <button className="btn btn-blue-trans"
                                    onClick={()=>this.handleAuthorization()}
                            > 
                                Delete Account { this.state.loading && <i className="fa fa-spinner fa-spin"/>  }
                            </button>
                        </div>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
