import React, {Component} from 'react';
import AuthHelper from "../../../libraries/auth-helper";
import ChartTotalLogin from "./chart-total-loggin";
// import ChartTotalUsers from "./chart-total-users";
import ChartRevenue from './chart-revenue';
import ChartTickets from "./chart-tickets";
import ChartFulfillment from "./chart-fulfillment";
import ChartSuccesFulLicensePlateRegistration from "./chart-user-successful-licensePlate-registration";
import ChartSuccesFulLicensePlateRegistrationV3 from "./chart-user-successful-licensePlateRegV3";
import ChartNewUserAccountsDevice from "./chart-new-user-accounts-device";
//import ChartDevice from "./chart-device";
import ChartTollTransaction from "./chart-toll-transaction";
import ChartPaymentStatusDeclined from "./chart-payment-status-declined";
import ChartPaymentRevenueSplit from "./chart-revenue-split"
//import ChartOld from "./chart-old";
import DashboardTotal from "./dashboard-total";

class ContentDashboard extends Component {

    constructor(props) {
        super(props);
        let profile = AuthHelper.getProfile()
        this.state = {
            role: profile.role["name"]
        }
    }

    render() {
        if (this.state.role === 'Super Admin' || this.state.role === 'Management') {
            return (
                <div>
                    <div className="row">
                        <div className="col-12">
                            <DashboardTotal/>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <ChartTotalLogin/>
                                </div>
                                <div className='col-md-6 col-sm-12'>
                                    <ChartNewUserAccountsDevice/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <ChartRevenue/>
                                </div>
                                <div className='col-md-6 col-sm-12'>
                                <ChartSuccesFulLicensePlateRegistrationV3/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <ChartTollTransaction/>
                                </div>
                                <div className='col-md-6 col-sm-12'>
                                    <ChartPaymentStatusDeclined/>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <ChartPaymentRevenueSplit/>
                                </div>
                            </div> */}
                            <div className='col-md-6 col-sm-12'>
                                <ChartPaymentRevenueSplit/>
                            </div>
                            {/* <ChartOld/> */}
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.role === 'Sales') {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-10 col-lg-9">
                            <ChartFulfillment/>
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.role === 'Customer Support Rep') {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-10 col-lg-9">
                            <ChartTickets/>
                        </div>
                    </div>
                </div>
            )
        }
        return false
    }
}

export default ContentDashboard;