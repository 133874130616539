import React, { useEffect, useRef, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Api from "../../../libraries/api";
// import * as bootstrap from 'bootstrap';
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from 'react-bootstrap/Alert';
import { Menu } from '@material-ui/core';
import { Ring } from '@uiball/loaders'
import * as d3 from 'd3';
import $ from 'jquery';

const PromoModal = (props) => {
    const didMount = useRef(false);
    const [source, setSource] = useState(1)
    const [formData, setFormData] = useState({
        promotionName: "",
        code: "",
        discount: 0,
        discountType: "PERCENTAGE",
        mainDescription: "",
        secondaryDescription: "",
        source: "FACEBOOK",
        applicationTimes: 0,
        termsAndConditions: "TEST",
        startDate: "",
        endDate: ""

    })
    const [discountSTR, setDiscountSTR] = useState('');
    const [applicationTimesSTR, setApplicationTimesSTR] = useState('');
    const [titleValidation, setTitleValidation] = useState(false);
    const [codeValidation, setCodeValidation] = useState(false);
    const [applicationTimesValidation, setApplicationTimesValidation] = useState(false);
    const [discountValidation, setDiscountValidation] = useState(false);
    const [startDateValidation, setStartDateValidation] = useState(false);
    const [endDateValidation, setEndDateValidation] = useState(false);
    const [dupPromoIndicator, setDupPromoIndicator] = useState(false);
    const numRegex = new RegExp(/^[0-9]{0,2}$/);
    const codeRegex = new RegExp(/^[A-Z0-9]+$/gm);
    // const titleRegex = new RegExp(/^[a-z]{0,40}$/);

    useEffect(() => {
        if (props.newOrUpdate == 'new') {
            setFormData(
                {
                    promotionName: "",
                    code: "",
                    discount: 0,
                    discountType: "PERCENTAGE",
                    mainDescription: "",
                    secondaryDescription: "",
                    source: "FACEBOOK",
                    applicationTimes: 0,
                    termsAndConditions: "TEST",
                    startDate: "",
                    endDate: ""
                }
            )
            setDiscountSTR('');
            setApplicationTimesSTR('');
        }
    }, [props.newOrUpdate])

    function openDupeAlert() {
        console.log(props.newOrUpdate)
        setDupPromoIndicator(true);
    }
    function closeDupeAlert() {
        setDupPromoIndicator(false);
    }


    function storeChange(e, str) {
        e.persist();
        if (str == 'discount') {
            setFormData(prevFormData => ({
                ...prevFormData,
                [str]: parseInt(e.target.value, 10)
            }))
            setDiscountSTR(e.target.value);            
        } else if (str == 'applicationTimes') {
            setFormData(prevFormData => ({
                ...prevFormData,
                [str]: parseInt(e.target.value, 10)
            }))
            setApplicationTimesSTR(e.target.value);
        }
         else if (str == 'source')  {
            setSource(e.target.value)
            setFormData(prevFormData => ({
                ...prevFormData,
                [str]: document.querySelector("#promo-source").innerHTML
            }))
        } else if (str == 'startDate' || str == 'endDate') {
            setFormData(prevFormData => ({
                ...prevFormData,
                [str]: e.target.value 
            }))
        } else if (str == 'promotionName') {
            setFormData(prevFormData => ({
                ...prevFormData,
                [str]: e.target.value
            }))
            if (e.target.value.length > 40) {
                setTitleValidation(true);
            }
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [str]: e.target.value
            }))
        }
    }

    useEffect(() => {
        if (didMount.current == true) {
            if (formData.promotionName.length > 40 && formData.promotionName != '') {
                setTitleValidation(true);
            } else {
                setTitleValidation(false);
            }
            if (!codeRegex.test(formData.code) && formData.code != '') {
                setCodeValidation(true);
            }
            if (codeRegex.test(formData.code)) {
                setCodeValidation(false);
            }
            if (formData.code == '') {
                setCodeValidation(false);
            }
            if (!numRegex.test(discountSTR)) {
                setDiscountValidation(true);
            } else if (numRegex.test(discountSTR)) {
                setDiscountValidation(false);
            }

            if (!numRegex.test(applicationTimesSTR)) {
                setApplicationTimesValidation(true);
            } else {
                setApplicationTimesValidation(false);
            }
            if (formData.startDate != '') {
                setStartDateValidation(false);
            }
            if (formData.endDate != '') {
                setEndDateValidation(false);
            }
        }


        didMount.current = true;
    }, [formData])

    function handleSubmit() {
        var indicator = props.newOrUpdate == 'update' ? 'update' : 'new';    
            if (formData.startDate == '') {
                setStartDateValidation(true);
            } 

            if (formData.endDate == '') {
                setEndDateValidation(true);
            }
            if (Date.parse(formData.endDate) < Date.parse(formData.startDate)) {
                setStartDateValidation(true);
                setEndDateValidation(true);
            }

            if (formData.code == '') {
                setCodeValidation(true);
            }
            if (formData.promotionName.length >= 40 || formData.promotionName == '') {
                setTitleValidation(true);
            }
            if (discountSTR == '') {
                setDiscountValidation(true);
            }
            if (applicationTimesSTR == '') {
                setApplicationTimesValidation(true);
            }
            if (props.codeList.includes(formData.code) && props.newOrUpdate == 'new') {
                console.log('bingo')
                openDupeAlert();
                return;
            }

            if (formData.promotionName.length >= 40 || (codeValidation || formData.code == '') || (discountValidation || discountSTR == '') || (applicationTimesValidation || applicationTimesSTR == '')
            || formData.startDate == '' || formData.endDate == '' || (Date.parse(formData.endDate) < Date.parse(formData.startDate))) {
                return;
            } else  {
                props.formSubmit(formData, indicator);
                props.setNewOrUpdate(null);
            }

    }

    function closeAlert() {
        props.setShowAlert(false)
    }

    function handlePromoClose() {
        props.handleClose()
        setFormData(
            {
                promotionName: "",
                code: "",
                discount: 0,
                discountType: "PERCENTAGE",
                mainDescription: "",
                secondaryDescription: "",
                source: "FACEBOOK",
                applicationTimes: 0,
                termsAndConditions: "TEST",
                startDate: "",
                endDate: ""
            }
        )
        setDiscountSTR('');
        setApplicationTimesSTR('');
        setStartDateValidation(false);
        setEndDateValidation(false);
        setTitleValidation(false);
        setCodeValidation(false);
        setApplicationTimesValidation(false);
        setDiscountValidation(false);
    }

    //
    useEffect(() => {
        if (props.newOrUpdate == 'update') {
            setDiscountSTR(props.selectedPromo['discount']);
            setApplicationTimesSTR(props.selectedPromo['applicationTimes']);
            setFormData(prevFormData => ({
                ...prevFormData,
                promotionName: props.selectedPromo['promotionName'],
                code: props.selectedPromo['code'],
                discount: props.selectedPromo['discount'],
                mainDescription: props.selectedPromo['mainDescription'],
                secondaryDescription: props.selectedPromo['secondaryDescription'],
                applicationTimes: props.selectedPromo['applicationTimes'],
                startDate: props.selectedPromo['startDate'].slice(0, 10),
                endDate: props.selectedPromo['endDate'].slice(0, 10)

            }))
        } else if (props.newOrUpdate == 'null') {
            setFormData(
                {
                    promotionName: "",
                    code: "",
                    discount: 0,
                    discountType: "PERCENTAGE",
                    mainDescription: "",
                    secondaryDescription: "",
                    source: "FACEBOOK",
                    applicationTimes: 0,
                    termsAndConditions: "TEST",
                    startDate: "",
                    endDate: ""
                }
            )
        }
    }, [props.newOrUpdate])


    return (
        <Modal centered show={props.showModal} onHide={handlePromoClose} style={{overflowY: "scroll"}} size='xl'>
            <Modal.Header closeButton style={{justifyContent: "center", alignItems: "center"}}>
                    {props.newOrUpdate == null ? <Ring size={35} /> : <Modal.Title className='mx-auto' >{props.newOrUpdate == 'update' ? 'Edit' : 'Add'} Promotion{props.newOrUpdate == 'update' && `  (ID: ${props.selectedPromo['ID']})`}</Modal.Title>}
            </Modal.Header>
            <Modal.Body centered>
                <Dialog
                    
                    open={dupPromoIndicator}
                    onClose={closeDupeAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                  
                    <DialogContent className='duplicate-dialog'>
                    <DialogContentText id="alert-dialog-description">
                        Promo Code {formData.code} already exists.  Please enter a new code.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions className='duplicate-dialog'>
                    {/* <Button onClick={closeDupeAlert} color="primary">
                        Disagree
                    </Button>
                    <Button onClick={closeDupeAlert} color="primary" autoFocus>
                        Agree
                    </Button> */}
                    </DialogActions>
                </Dialog>
                <Container style={{justifyContent: "center", alignItems: "center"}}>
                    <Row className='mx-auto'>
                        <Col xs={12}>
                            <div className='alert-cotainer' style={{minWidth: '85%'}}>
                                <Alert show={props.showAlert} variant="danger">
                                    <Alert.Heading className='text-center'>Request Unsuccessful</Alert.Heading>
                                    <p>{`Error: ${props.errorMSG}`}</p>
                                    <div className="d-flex justify-content-end">
                                        <Button onClick={() => closeAlert()} variant="outline-success">
                                            Close
                                        </Button>
                                    </div>
                                </Alert>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mx-auto' style={{marginBottom: "75px"}}>
                        <Col md={4}>
                            <TextField
                                error={titleValidation}
                                helperText={titleValidation ? 'Max 40 Characters' : null}
                                id="promo-title"
                                label="Promo Title"
                                className="textinput"
                                variant='outlined'
                                InputLabelProps={{
                                shrink: true,
                                }}
                                style={{marginRight: '10px'}}
                                onChange={e => storeChange(e, 'promotionName')}
                                value={formData.promotionName}
                            />
                        </Col>
                        <Col md={4}>
                            <TextField
                                // error={formData.code == '' ? false : !codeRegex.test(formData.code)}
                                error={codeValidation}
                                helperText={codeValidation ? 'Uppercase Letters & Numbers Only' : null}
                                id="promo-code"
                                label="Promo Code"
                                className="textinput"
                                variant='outlined'
                                InputLabelProps={{
                                shrink: true,
                                }}
                                style={{marginRight: '10px'}}
                                onChange={e => storeChange(e, 'code')}
                                value={formData.code}
                            />
                        </Col>
                        <Col md={4}>
                            <TextField
                                error={discountValidation}
                                helperText={discountValidation ? '1-2 Digit Numbers' : ''}
                                id="promo-discount"
                                label="Discount %"
                                className="textinput"
                                variant='outlined'
                                InputLabelProps={{
                                shrink: true,
                                }}
                                style={{marginRight: '10px'}}
                                onChange={e => storeChange(e, 'discount')}
                                value={discountSTR}
                            />
                        </Col>
                    </Row>
                    <Row className='mx-auto' style={{marginBottom: "75px"}}>
                        <Col md={4}>
                            <TextField
                                id="promo-type"
                                label="Discount Type"
                                className="textinput"
                                variant='outlined'
                                disabled
                                InputProps={{readOnly: true}}
                                style={{marginRight: '10px'}}
                                defaultValue={"PERCENTAGE"}
                                
                            />
                        </Col>
                        <Col md={4}>
                            <TextField
                                error={applicationTimesValidation}
                                helperText={applicationTimesValidation ? '1-2 Digit Numbers' : ''}
                                id="promo-applications"
                                label="# of Applications"
                                className="textinput"
                                variant='outlined'
                                InputLabelProps={{
                                shrink: true,
                                }}
                                style={{marginRight: '10px'}}
                                onChange={e => storeChange(e, 'applicationTimes')}
                                value={applicationTimesSTR}
                            />
                        </Col>

                        <Col md={4}>
                            <TextField
                                select
                                id="promo-source"
                                label="Source"
                                className="textinput"
                                variant='outlined'
                                onChange={(e) => storeChange(e, 'source')}
                                style={{marginRight: '10px', minWidth: "194px"}}
                                value={source}
                            >
                                <MenuItem value={1}>
                                    FACEBOOK
                                </MenuItem>
                                <MenuItem value={2}>
                                    GOOGLE
                                </MenuItem>
                                <MenuItem value={3}>
                                    CUSTOM
                                </MenuItem>
                            </TextField>
                        </Col>
                    </Row>
                    <Row className='mx-auto justify-content-center' style={{marginBottom: "75px"}}>
                    <Col md={4} >
                        <TextField
                            error={startDateValidation}
                            helperText={startDateValidation ? 'Select a Valid Date' : null}
                            id="start-date"
                            label="Start Date"
                            type="date"
                            variant='outlined'
                            // defaultValue="2021-01-01"
                            className="search-start-date"
                            onChange={(e) => storeChange(e, 'startDate')}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            style={{marginRight: '10px', minWidth: "194px"}}
                            value={formData.startDate}
                        />
                    </Col>
                    <Col md={4}>
                        <TextField
                            error={endDateValidation}
                            helperText={endDateValidation ? 'Select a Valid Date' : null}
                            id="end-date"
                            label="End Date"
                            type="date"
                            variant="outlined"
                            // defaultValue="2021-01-01"
                            className="search-start-date"
                            onChange={(e) => storeChange(e, 'endDate')}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            style={{marginRight: '10px', minWidth: "194px"}}
                            value={formData.endDate}
                        />
                    </Col>
                    <Col md={4}>
                            <TextField
                                id="promo-terms"
                                label="Terms"
                                className="textinput"
                                variant='outlined'
                                disabled
                                InputProps={{readOnly: true}}
                                style={{marginRight: '10px'}}
                                defaultValue={"DEFAULT"}
                            />
                        </Col>
                        
                    </Row>
                    <Row className='mx-auto' style={{marginBottom: "50px"}}>
                        <Col md={12}>
                        <TextField
                            id="outlined-multiline-flexible"
                            multiline
                            label="Main Description"
                            maxRows={4}
                            // value={value}
                            onChange={e => storeChange(e, 'mainDescription')}
                            variant="outlined"
                            style={{marginRight: '10px', minWidth: "85%"}}
                            value={formData.mainDescription}
                        />
                        </Col>
                    </Row>
                    <Row className='mx-auto'>
                        <Col md={12}>
                            <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                label="Secondary Description"
                                maxRows={4}
                                // value={value}
                                onChange={e => storeChange(e, 'secondaryDescription')}
                                variant="outlined"
                                style={{marginRight: '10px', minWidth: "85%"}}
                                value={formData.secondaryDescription}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handlePromoClose}>
                Close
                </Button>
                {props.newOrUpdate == 'update' && <Button variant="danger" onClick={() => props.handleDelete(formData)}>
                Delete
                </Button>}
                <Button variant="primary" onClick={() => handleSubmit()}>
                Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )

}
export default PromoModal;