import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment";
import DateFormat from "../../../libraries/data-format-helper";
import Tooltip from "@material-ui/core/Tooltip";  // Tooltip for error
import ErrorIcon from "@material-ui/icons/Error";  // Error icon for date issue
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class SalesOrderedLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rows: [],
      perPage: 50,
      currentPage: 0,
      totalData: 0,
      search: "",
      sortBy: "createdAt", // Default sorting field
      sortOrder: "desc", // Default sorting order
      start_date: null, // Date filter
      end_date: null, // End date filter
      dateError: false,  // Error tracking for invalid date ranges
    };
    this.getPurchaseOrdered();
  }

  handleChange = (e, prop) => {
    this.setState({ [prop]: e.target.value });
  };

  // Handle date changes
  handleDateChange = (date) => {
    this.setState({ start_date: date }, () => {
      this.getPurchaseOrdered(); // Call API after date change
    });
  };

  handleEndDateChange = (date) => {
    if (this.state.start_date && moment(date).isBefore(this.state.start_date)) {
      this.setState({ dateError: true });
    } else {
      this.setState({ end_date: date, dateError: false }, () => {
        this.getPurchaseOrdered();
      });
    }
  };

  // Fetch the purchase orders with pagination, search, sorting, and date filtering
  getPurchaseOrdered = () => {
    const { perPage, currentPage, search, sortBy, sortOrder, start_date, end_date } = this.state;
    const token = localStorage.getItem('access_token');

    let url = `https://stg.api.neoride.com/api/parking/v1/orders/status/ordered?limit=${perPage}&page=${currentPage + 1}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`;

    // Append start and end date if provided
    if (start_date) {
      url += `&start_date=${moment(start_date).format("YYYY-MM-DD")}`;
    }
    if (end_date) {
      url += `&end_date=${moment(end_date).format("YYYY-MM-DD")}`;
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    })
      .then(response => response.json())
      .then(resp => {
        if (resp) {
          this.setState({
            rows: resp.orders || [],  // Ensure rows is always an array
            totalData: resp.total || 0,
            loading: false,
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          rows: [], // Set to empty array on error
          totalData: 0,
          loading: false,
        });
      });
  };

  // Handle search when Enter is pressed or when the search button is clicked
  handleKeyPressSearch = (event) => {
    if (event.key === "Enter") {
      this.setState({ rows: [], currentPage: 0, loading: true }, () => {
        this.getPurchaseOrdered();
      });
    }
  };

  handleSearchClick = () => {
    this.setState({ rows: [], currentPage: 0, loading: true }, () => {
      this.getPurchaseOrdered();
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ rows: [], currentPage: newPage, loading: true }, () => {
      this.getPurchaseOrdered();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ perPage: event.target.value, currentPage: 0, loading: true }, () => {
      this.getPurchaseOrdered();
    });
  };

  // Handle when an order row is clicked
  handlePurchaseOrdered = (orderId) => {
    console.log(`Order ID clicked: ${orderId}`);
    // Add your logic here for handling the click, e.g., redirecting to order details page
  };

  // Handle sorting functionality
  handleSort = (field) => {
    if (field === 'createdAt') {
      const { sortBy, sortOrder } = this.state;
      let newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'; // Toggle sorting order
      this.setState({ sortBy: field, sortOrder: newSortOrder, loading: true }, () => {
        this.getPurchaseOrdered();
      });
    }
  };

  renderSortArrow = (field) => {
    const { sortBy, sortOrder } = this.state;
    if (sortBy === field) {
      return sortOrder === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return null; // No arrow if the field is not sorted
  };

  render() {
    return (
      <div className="row">
        <div className="col-12 card">
          <div className="row align-items-center">
            <div className="col-md-4 search-bar">
              <TextField
                type="text"
                id="adornment-search-ordered"
                name="search"
                placeholder="Search Orders"
                onChange={(e) => this.handleChange(e, "search")}
                onKeyPress={this.handleKeyPressSearch}
                value={this.state.search}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="Search button" onClick={this.handleSearchClick}>
                        <i className="fas fa-search"> </i>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </div>

            {/* Start Date Picker */}
            <div className="col-md-2 filter">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  margin="normal"
                  id="start_date_ordered"
                  label="Start Date"
                  format="MM/DD/YYYY"
                  value={this.state.start_date}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{ "aria-label": "change start date" }}
                />
              </MuiPickersUtilsProvider>
            </div>

            {/* End Date Picker */}
            <div className="col-md-2 filter" style={{ display: 'flex', alignItems: 'center' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  margin="normal"
                  id="end_date_ordered"
                  label="End Date"
                  format="MM/DD/YYYY"
                  value={this.state.end_date}
                  onChange={this.handleEndDateChange}
                  KeyboardButtonProps={{ "aria-label": "change end date" }}
                  // Apply red border if there's an error
                  style={{
                    borderColor: this.state.dateError ? 'red' : 'initial',
                    borderWidth: this.state.dateError ? '2px' : '1px',
                    borderStyle: this.state.dateError ? 'solid' : 'none',
                  }}
                />
              </MuiPickersUtilsProvider>

              {/* Display tooltip with error icon if there's an error */}
              {this.state.dateError && (
                <Tooltip title="End date cannot be earlier than start date" placement="right">
                  <ErrorIcon style={{ color: 'red', marginLeft: '5px' }} />
                </Tooltip>
              )}
            </div>
          </div>

          <div className="table-wrapper">
            <Table className="table-list mt-3" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Order</TableCell>
                  <TableCell onClick={() => this.handleSort('createdAt')}>
                    Request Date {this.renderSortArrow('createdAt')}
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Zip Code</TableCell>
                </TableRow>
              </TableHead>

              {this.state.loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} align="center" className="py-5">
                      <CircularProgress color="primary" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <PurchaseOrderedTable
                  rows={this.state.rows}
                  handlePurchaseOrdered={this.handlePurchaseOrdered}  // Pass the handler
                />
              )}
            </Table>
          </div>

          {/* Pagination */}
          <div>
            <TablePagination
              rowsPerPageOptions={[50, 75, 100]}
              component="div"
              count={this.state.totalData}
              rowsPerPage={this.state.perPage}
              page={this.state.currentPage}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onPageChange={this.handleChangePage}  // Use the new onPageChange prop
              onRowsPerPageChange={this.handleChangeRowsPerPage}  // Use the new onRowsPerPageChange prop
              ActionsComponent={TablePaginationActions}
            />
          </div>

          {/* Toast Container */}
          <ToastContainer />
        </div>
      </div>
    );
  }
}

class PurchaseOrderedTable extends Component {
  render() {
    const { rows, handlePurchaseOrdered } = this.props;  // Accept the handler as a prop

    if (!rows || rows.length === 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={5} align="center">
              No records found
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody>
        {rows.map((item, i) => (
          <TableRow key={i} onClick={() => handlePurchaseOrdered(item.id)}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{DateFormat.dateTime(item.createdAt)}</TableCell>
            <TableCell>{item.status}</TableCell>
            <TableCell>{item.phone}</TableCell>
            <TableCell>{item.postalCode}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }
}
