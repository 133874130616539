import React, { Component } from 'react';
import Chart from "chart.js";
import Api from "../../../libraries/api";
import { CircularProgress, TextField, Tooltip } from "@material-ui/core";

class ChartDevice extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            period: 'daily',
            loading: false,
            startDate: '',
            endDate: '',
            page: 1,
            limit: 7,
            errorMessage: '',
            chartData: null,
            location: '',
            previousState: {},
            preventDateUpdate: false, // Flag to control date field updates
            isDrillDown: false, // New flag to track drill-down state
            showTooltip: false, // New flag to control tooltip visibility
            dateFilterActive: false, // Flag to indicate date filtering is active
            monthDrillDownActive: false,
        };
        this.chartUpdating = false; // Flag to prevent multiple updates
        this.drillDownInProgress = false; // Flag to prevent multiple drill-down calls
    }

    componentDidMount() {
        this.getDeviceChart();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.endDate && !this.state.isDrillDown && (
            prevState.period !== this.state.period || 
            prevState.page !== this.state.page ||
            prevState.startDate !== this.state.startDate || 
            prevState.endDate !== this.state.endDate)) { 
            this.getDeviceChart();
        }
    }

    getDeviceChart = () => {
        const { period, startDate, endDate, page, limit, loading} = this.state;
        // if (loading) {
        //     this.setState({ errorMessage: 'Please wait for the current operation to finish.' });
        //     return;
        // }
        if (loading) {
            this.setErrorWithTimeout('Please wait for the current operation to finish.');
            return;
        }
        

        if ((!startDate && !endDate) || (this.isValidDate(startDate) && this.isValidDate(endDate))) {
            const url = `/reports/user-device-v2?period=${period}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
            this.setState({ loading: true});
            Api.get(url, true).then(resp => {
                if (resp.data) {
                    const { columns: labels, android, ios, iosPercentage, androidPercentage } = resp.data["userDevice"];
                    const chartData = { labels, android, ios, iosPercentage, androidPercentage };
                    this.setState({ loading: false, chartData }, () => {
                        this.updateChart(labels, android, ios, iosPercentage, androidPercentage);
                    });
                } else {
                    throw new Error("No data received");
                }
            }).catch(err => {
                this.setState({
                    errorMessage: "An error occurred while fetching data.",
                    loading: false
                });
            });
        }
    }

    updateChart = (labels, android, ios, iosPercentage, androidPercentage) => {
        if (this.chartUpdating) return; // Prevent multiple updates
        this.chartUpdating = true;
        this.drillDownInProgress = false; // Initialize the flag

        const maxValue = Math.max(...android, ...ios);
        const yMax = Math.ceil(maxValue / 10) * 10;

        if (this.chartRef.current) {
            const barChartData = {
                labels,
                datasets: [
                    {
                        label: 'Android Users',
                        backgroundColor: '#008299',
                        borderColor: '#008299',
                        borderWidth: 1,
                        datalabels: { color: 'transparent' },
                        data: android
                    },
                    {
                        label: 'iOS Users',
                        backgroundColor: '#10d7ee',
                        borderColor: '#10d7ee',
                        borderWidth: 1,
                        datalabels: { color: 'transparent' },
                        data: ios
                    }
                ]
            };

            const myChartRef = this.chartRef.current.getContext("2d");
            const chartInstance = new Chart(myChartRef, {
                type: 'bar',
                data: barChartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0,
                                max: yMax
                            }
                        }]
                    },
                    legend: { position: 'top' },
                    tooltips: {
                        enabled: true,
                        mode: 'nearest',
                        intersect: true,
                        callbacks: {
                            label: function(tooltipItem, data) {
                                const dataset = data.datasets[tooltipItem.datasetIndex];
                                const value = tooltipItem.value;
                                if (dataset.label === 'iOS Users') {
                                    const percentage = iosPercentage[tooltipItem.index];
                                    return `${dataset.label}: ${value} (${percentage}%)`;
                                } else {
                                    const p = androidPercentage[tooltipItem.index];
                                    return `${dataset.label}: ${value} (${p}%)`;
                                }
                            }
                        }
                    },
                    onClick: (event, elements) => {
                        if (elements.length > 0 && this.state.period === 'monthly' && !this.drillDownInProgress) {
                            this.drillDownInProgress = true; // Set the flag
                            const index = elements[0]._index;
                            const label = labels[index];
                            this.drillDownToDailyData(label);
                        }
                    }
                }
            });
            chartInstance.update();
        } else {
            console.error("Chart ref is not available.");
        }
        
        this.chartUpdating = false; // Reset the flag after updating
    }

    drillDownToDailyData = (monthLabel) => {
        this.state.isDrillDown = true;
        this.state.monthDrillDownActive = true;
        if (monthLabel.split('/').length === 2){
            const [month, year] = monthLabel.split('/');
            const formattedYear = `20${year}`; // Assuming the year is provided as "24" for "2024"
            const formattedMonth = month.padStart(2, '0');
            const startDate = `${formattedYear}-${formattedMonth}-01`;
            const endDate = `${formattedYear}-${formattedMonth}-${new Date(formattedYear, formattedMonth, 0).getDate()}`;
            console.log('Drilling down to daily data for:', { startDate, endDate });

            this.setState(prevState => ({
                previousState: { ...prevState },
                startDate,
                endDate,
                page: 1,
                limit: 31,
                location: `Month -> ${new Date(formattedYear, formattedMonth - 1).toLocaleString('default', { month: 'long' })} ${formattedYear}`,
                preventDateUpdate: true, // Set flag to prevent date field updates
                // isDrillDown: true, // Set flag to indicate a drill-down operation
            }), () => {
                this.getDeviceChart();
                this.setState({ showTooltip: true }); // Show tooltip after data is loaded
                setTimeout(() => {
                    this.setState({ showTooltip: false }); // Hide tooltip after 5 seconds
                }, 5000);
            });
        }
    };

    handleChange = (target) => {
        this.setState({
            period: target,
            page: 1,
            limit: 7,
            errorMessage: '',
            startDate: '',
            endDate: '',
            location: '',
            preventDateUpdate: false, // Reset flag when changing period
            isDrillDown: false, // Reset drill-down flag when changing period
            showTooltip: false, // Hide tooltip when changing period
            dateFilterActive: false,
            monthDrillDownActive: false,
        }, () => {
            this.getDeviceChart();
        });
    };

    handleDateChange = (event) => {
        const { name, value } = event.target;
        
        if (this.state.preventDateUpdate) {
            console.log('Preventing date update for:', name, value);
            return;
        }
        
        if (value && !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
            console.log(`Invalid date format for ${name}:`, value);
            return; // Exit if the date format is incorrect
        }
        
        this.setState({ [name]: value }, () => {
            const { startDate, endDate } = this.state;
            if (startDate && endDate && this.isValidDate(startDate) && this.isValidDate(endDate)) {
                // if (new Date(startDate) > new Date(endDate)) {
                //     this.setState({ errorMessage: 'Start date cannot be later than end date.' });
                // } 
                if (new Date(startDate) > new Date(endDate)) {
                    this.setErrorWithTimeout('Start date cannot be later than end date.');
                }                
                else {
                    this.getDeviceChart(); // Make the API call only if both dates are valid and correctly ordered
                }
            }
        });
    };

    isValidDate = (dateStr) => {
        if (!dateStr || !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) return false;
        const [year, month, day] = dateStr.split('-').map(Number);
        if (year < 1000 || year > 3000 || month < 1 || month > 12) return false;
        const date = new Date(year, month - 1, day);
        return date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day;
    };
    
    
    
    handleLocationClick = () => {
        const { previousState } = this.state;
        this.setState({
            period: previousState.period,
            startDate: '',
            endDate: '',
            page: previousState.page,
            limit: 7,
            chartData: previousState.chartData,
            location: '',
            previousState: {}, // Reset previousState to prevent unintended reuse
            preventDateUpdate: false, // Reset flag when reverting state
            isDrillDown: false, // Reset drill-down flag when reverting state
            showTooltip: false, // Hide tooltip when reverting state
            monthDrillDownActive: false,
        }, this.getDeviceChart);
    };

    scroll = (direction) => {
        this.setState(prevState => {
            let newPage = prevState.page + direction;
            let errorMessage = '';

            if (this.state.loading) {
                // errorMessage = 'Please wait for the current operation to finish.';
                this.setErrorWithTimeout('Please wait for the current operation to finish.');
                newPage = prevState.page; // Prevent page change during loading
            } else {
                if (this.state.period === 'monthly' && newPage === 3) {
                    newPage = 2;
                    // errorMessage = 'Page limit met.';
                    this.setErrorWithTimeout('Page limit met.')
                }
                if (newPage < 1) {
                    newPage = 1;
                    // errorMessage = 'Already on the latest page.';
                    this.setErrorWithTimeout('Already on the latest page.');
                } else if (newPage > 15) {
                    newPage = 15;
                    // errorMessage = 'Page limit met.';
                    this.setErrorWithTimeout('Page limit met.');
                }
            }

            return { page: newPage, errorMessage };
        }, this.getDeviceChart);
    };

    dismissError = () => {
        this.setState({ errorMessage: '' });
    };

    setErrorWithTimeout = (errorMessage) => {
        this.setState({ errorMessage }, () => {
            setTimeout(() => {
                this.setState({ errorMessage: '' });
            }, 5000);
        });
    }
    

    render() {
        const { loading, errorMessage, chartData, location, showTooltip } = this.state;
        return (
            <div>
                <div className="col-12" style={{ marginTop: '20px', padding: '0px' }}>
                    <div className="col-12 card">
                        <div className="row title-dashboard">
                            <div className="col-6">
                                <Tooltip title="Number of users who subscribed to the Neoride App">
                                    <h2>New User Accounts</h2>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4 mb-4" style={{ padding: '0 20px' }}>
                            <div style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: location ? 'center' : 'flex-start',
                                alignItems: 'center',
                                border: location ? '1px solid #ccc' : 'none',
                                borderRadius: '4px',
                                padding: location ? '4px 12px' : '4px',
                                marginRight: '10px',
                                position: 'relative' // Added position relative
                            }}>
                                {location && (
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <span style={{
                                            cursor: 'pointer',
                                            marginRight: '5px',
                                            color: '#008299',
                                            fontWeight: 'bold',
                                            fontSize: '0.875rem'
                                        }} onClick={this.handleLocationClick}>
                                            Month
                                        </span>
                                        <span style={{
                                            color: '#555',
                                            cursor: 'default',
                                            fontSize: '0.875rem',
                                            fontWeight: '300'
                                        }}>
                                            {location.replace('Month', '')}
                                        </span>
                                    </div>
                                )}
                                <Tooltip
                                    title="Please click here to go to the previous state"
                                    open={showTooltip}
                                    disableHoverListener
                                    disableFocusListener
                                    disableTouchListener
                                    arrow
                                    placement="top"
                                >
                                    <span></span>
                                </Tooltip>
                            </div>
                            <nav className="nav nav-filter" style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 1
                            }}>
                                <button className={`nav-link pl-0 ${this.state.period === 'daily' ? 'active' : ''}`} onClick={() => this.handleChange('daily')}>Daily</button>
                                <button className={`nav-link ${this.state.period === 'weekly' ? 'active' : ''}`} onClick={() => this.handleChange('weekly')}>Weekly</button>
                                <button className={`nav-link ${this.state.period === 'monthly' ? 'active' : ''}`} onClick={() => this.handleChange('monthly')}>Monthly</button>
                            </nav>
                            <div style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                background: 'transparent',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '4px 12px'
                            }}>
                            <Tooltip
                                title={this.state.period !== 'daily' ? "Click on 'Daily' to enable date filter" : ''}
                                placement="top"
                            >
                                <div>
                                    <TextField
                                        label="Start Date"
                                        type="date"
                                        name="startDate"
                                        value={this.state.period === 'daily' ? this.state.startDate : ''}
                                        // onChange={this.state.period === 'daily' ? this.handleDateChange : undefined}
                                        onChange = {this.state.period === 'daily' ? this.handleDateChange : undefined}
                                        disabled={this.state.period !== 'daily'}
                                        InputLabelProps={{ shrink: true }}
                                        style={{
                                            marginRight: '10px',
                                            maxWidth: '140px',
                                            fontSize: '0.875rem',
                                            fontFamily: 'Arial, sans-serif'
                                        }}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip
                                title={this.state.period !== 'daily' ? "Click on 'Daily' to enable date filter" : ''}
                                placement="top"
                            >
                                <div>
                                    <TextField
                                        label="End Date"
                                        type="date"
                                        name="endDate"
                                        value={this.state.period === 'daily' ? this.state.endDate : ''}
                                        // onChange={this.state.period === 'daily' ? this.handleDateChange : undefined}
                                        onChange = {this.state.period === 'daily' ? this.handleDateChange : undefined}
                                        disabled={this.state.period !== 'daily'}
                                        InputLabelProps={{ shrink: true }}
                                        style={{
                                            maxWidth: '140px',
                                            fontSize: '0.875rem',
                                            fontFamily: 'Arial, sans-serif'
                                        }}
                                    />
                                </div>
                            </Tooltip>

                            </div>
                        </div>
                        {errorMessage && (
                            <div
                                style={{ color: 'red', textAlign: 'center', cursor: 'pointer' }}
                                onClick={this.dismissError}
                            >
                                {errorMessage}
                            </div>
                        )}
                        <div className="d-flex align-items-center">
                            <Tooltip title={this.state.dateFilterActive ? "Button disabled during date filter" : (this.state.monthDrillDownActive ? "Button disabled during month drill down" : "")} placement="top">
                                <span>
                                    <button onClick={() => this.scroll(1)} className="btn-scroll" disabled={this.state.dateFilterActive || this.state.monthDrillDownActive}>◀</button>
                                </span>
                            </Tooltip>
                            <div className="chart-container" style={{ flex: 1 }}>
                                {loading && <CircularProgress />}
                                {chartData && !loading && <canvas ref={this.chartRef} />}
                            </div>
                            <Tooltip title={this.state.dateFilterActive ? "Button disabled during date filter" : (this.state.monthDrillDownActive ? "Button disabled during month drill down" : "")} placement="top">
                                <span>
                                    <button onClick={() => this.scroll(-1)} className="btn-scroll" disabled={this.state.dateFilterActive || this.state.monthDrillDownActive}>▶</button>
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChartDevice;