import React, { useEffect, useRef, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Api from "../../../libraries/api";
// import * as bootstrap from 'bootstrap';
import { Modal, Button } from "react-bootstrap";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from 'react-bootstrap/Alert';
import { Menu } from '@material-ui/core';
import PromoModal from './promo-modal'
import * as d3 from 'd3';
import $ from 'jquery';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import Slide from '@material-ui/core/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} timeout={0} {...props} />;
  });

const Promotions = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [newUpdate, setNewUpdate] = useState(0);
    const [promoPct, setPromoPct] = useState(null);
    const [useNumber, setUseNumber] = useState(null);
    const [promoData, setPromoData] = useState(null);
    const [selectedPromo, setSelectedPromo] = useState(null)
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [newOrUpdate, setNewOrUpdate] = useState(null);
    const [codeList, setCodeList] = useState(null);
    const [errorMSG, setErrorMSG] = useState(null);


    const didMount = useRef(false);
    const sourceList = ["FACEBOOK", "GOOGLE", "CUSTOM"]
    


    function getPromoData() {
        Api.getWithHost('/promotional_code', true, 'topup').then(resp => {
            setPromoData(resp.data);
            var codeArray = [];
            Object.entries(resp.data).forEach((d, i) => {
                codeArray.push(d[1].code)
            })
            console.log(codeArray);
            console.log(codeArray.includes("CESS1"))
            setCodeList(codeArray);
        });

    }
    function dismissSuccessAlert() {
        setShowSuccess(false);
        setNewUpdate(prevNewUpdate => prevNewUpdate += 1)
    }




    function openModal() {
        setShowModal(true);
        setNewOrUpdate('new');
    }


    function editPromo(e, id) {
        setNewOrUpdate('update');
        setSelectedPromo(promoData.find(item => item.ID == id))
        setShowModal(true);
        // document.getElementById("promo-title").value = "wowowo"
        d3.select("#promo-title")
            .attr("value", "asdf")
    }

    function handleClose() {
        setShowModal(false);
        setNewOrUpdate(null);
    }

    function handleDelete(formData, indicator) {

        Api.deleteWithHost(`/promotional_code/${selectedPromo.ID}`, formData, true, 'topup').then(resp => {
            if (resp.code == 200) {
                setShowModal(false);
                setShowSuccess(true);
                setNewOrUpdate('delete');
                setTimeout(() => {
                    dismissSuccessAlert();
                }, 2000);
            }
        }).catch(error => {
            setShowAlert(true);
            setErrorMSG(error.error);
        })
    }

    function handleAlertClose() {
        setShowAlert(false);
    }

    function formSubmit(formData, indicator) {
        formData['startDate'] = formData['startDate'] + "T00:00:00Z"
        formData['endDate'] = formData['endDate'] + "T00:00:00Z"

        if (indicator == 'new') {
            Api.postWithHost('/promotional_code', formData, true, 'topup').then(resp => {
                if (resp.code == 200) {
                    setShowModal(false);
                    setShowSuccess(true);
                    setTimeout(() => {
                        dismissSuccessAlert();
                    }, 2000);
                }
            }).catch(error => {
                setShowAlert(true);
                setErrorMSG(error.error);
                // if (error.code = 500) {

                // }
            })
        } else if  (indicator == 'update') {
            Api.putWithHost(`/promotional_code/${selectedPromo.ID}`, formData, true, 'topup').then(resp => {
                if (resp.code == 200) {
                    setShowModal(false);
                    setShowSuccess(true);
                    // setNewOrUpdate(null);
                    setTimeout(() => {
                        dismissSuccessAlert();
                    }, 2000);
                }
            }).catch(error => {
                setShowAlert(true);
                setErrorMSG(error.error);
                // if (error.code = 500) {

                // }
            })
        }
    }

    useEffect(() => {
        getPromoData();
        didMount.current = true;
    }, [newUpdate])

    if (!promoData || !didMount.current) {
        return <pre>Loading...</pre>
    } else {
        return(
            <>
            <div className='container-fluid '>
                <div className='row'>
                    <div className='col-12'>
                        <h1>Promotions</h1>
                    </div>
                </div>
                <div className='row'>
                    <div className=" col-12 card">
                        <div className='row'>
                            <div className='col-md-3' style={{maxWidth: '165px', marginLeft: "10px"}}>
                                <a className='btn btn-promo' href='#' onClick={openModal} >Add Promo</a>
                            </div>
                            <div className='col-md-9' style={{minWidth: '50%'}}>
                                {/* <Alert className='fade show' show={showSuccess} variant="success">
                                    <Alert.Heading className='text-center'>Success!</Alert.Heading>
                                    <div className="d-flex justify-content-end">
                                        <Button onClick={dismissSuccessAlert} variant="outline-success">
                                            Close
                                        </Button>
                                    </div>
                                </Alert> */}
                                 <Dialog
                                    fullWidth
                                    maxWidth='sm'
                                    open={showSuccess}
                                    onClose={dismissSuccessAlert}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    className=''
                                    TransitionComponent={Transition}
                                    TransitionProps={{
                                        style: {
                                            transitionDelay: 0,
                                            
                                        }
                                    }}
                                >
                                    <DialogTitle  disableTypography="true" className='success-dialog' id="alert-dialog-title" style={{backgroundColor: 'rgba(45, 141, 54, 0.8)', color: 'white', fontSize: '24px' }}>{`Promo ${newOrUpdate == 'update' ? 'Updated' : newOrUpdate == 'delete' ? 'Deleted' : 'Added'} Successfully!`} </DialogTitle>
                                    
                                    <DialogActions className='success-dialog'>
                                    {/* <Button onClick={dismissSuccessAlert} color="primary">
                                        Disagree
                                    </Button> */}
                                    {/* <Button onClick={dismissSuccessAlert} color="primary" autoFocus className='success-text'>
                                        Close
                                    </Button> */}
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                        <div className='row'>
                            <Table className="table-list mt-3" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Headline</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Discount</TableCell>
                                        <TableCell>Applications</TableCell>
                                        <TableCell>Start</TableCell>
                                        <TableCell>End</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {promoData.map((row, index) => {
                                        return(
                                            <TableRow key={row.ID} onClick={(e) => editPromo(e, row.ID)}>
                                                <TableCell>{row.ID}</TableCell>
                                                <TableCell>{row.promotionName}</TableCell>
                                                <TableCell>{row.code}</TableCell>
                                                <TableCell>{row.discount}</TableCell>
                                                <TableCell>{row.applicationTimes}</TableCell>
                                                <TableCell>{row.startDate.slice(0,10)}</TableCell>
                                                <TableCell>{row.endDate.slice(0,10)}</TableCell>
                                                <TableCell>{row.status}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <PromoModal data={promoData} errorMSG={errorMSG} showModal={showModal} showAlert={showAlert} setShowAlert={setShowAlert} handleClose={handleClose} 
            formSubmit={formSubmit} newOrUpdate={newOrUpdate} setNewOrUpdate={setNewOrUpdate} selectedPromo={selectedPromo} handleDelete={handleDelete} codeList={codeList}/>
           
          </>
        )
    }

}
const mapStateToProps = (state) => {
    return {

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(RootActions, dispatch)

};
export default connect(mapStateToProps, mapDispatchToProps)(Promotions);