import React, {Component} from 'react';
import {toast, ToastContainer} from "react-toastify";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TransponderPrice from '../../presentational/transponder-price';
import DeliveryProviders from '../../presentational/delivery-providers';




toast.configure({
    autoClose: 2000,
    draggable: false,
});

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


class Fulfillment extends Component {
    constructor(props) {

        super(props);

        this.state = {
            value: 0,
            id: '',
        };

        this.handleChangeTabs = this.handleChangeTabs.bind(this);
    }

    componentDidMount() {
        this.__fetchData(false);
    }

    __fetchData = update => {

    };

    handleChangeTabs(e, newValue) {

        this.setState({

            value: newValue

        })
    };


    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <AppBar position="static" color="default" style={{marginLeft:'-20px',width:'103%'}}>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className='text-title-big' style={{marginBottom:'20px'}}>Fulfillment</h2>
                            </div>
                        </div>

                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChangeTabs}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Delivery Providers" {...a11yProps(0)} />
                            <Tab label="Toll Tag Price" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>

                    <TabPanel value={this.state.value} index={0}>
                        <DeliveryProviders/>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        <TransponderPrice/>
                    </TabPanel>
                </div>
                <ToastContainer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state.permission_state)
    return {

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {
    console.log(dispatch)
    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Fulfillment);