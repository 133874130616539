import React, { useEffect, useRef, useState } from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const SearchInput = (props) => {

    // console.log(props);
    const [searchText, setSearchText] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [typeInput, setTypeInput] = useState('');
    const [checkedState, setCheckedState] = useState(false);

    useEffect(() => {
        props.fetchData(false);
    }, [searchText, startDate, endDate, typeInput])

    function storeChange(e, item) {
        if (item === 'search') {
            // console.log(`search: ${e.target.value}`);
            setSearchText(e.target.value);
            props.handleChange(e.target.value, 'search');

        } else if (item === 'startDate') {
            // console.log(`start: ${e.target.value}`);
            setStartDate(e.target.value);
            props.handleChange(e.target.value, 'startDate');

        }else if (item === 'endDate') {
            // console.log(`end: ${e.target.value}`);
            setEndDate(e.target.value);
            props.handleChange(e.target.value, 'endDate');
        } else if (item === 'type') {
            console.log(document.querySelector("#transaction-type-select").innerHTML)
            setTypeInput(e.target.value);
            props.handleChange(e.target.value, 'filter');
        } else if (item === 'check') {
            setCheckedState(prevCheckedState => {
                props.handleChange(!prevCheckedState, 'promoCheck')
                return !prevCheckedState
            })
        }
    }

    return (
            <Container maxWidth={false} spacing={0} justify='center'>
                <Grid container  spacing={3} direction='row' justify='start'>
                    {!props.userID && <Grid item md={12} lg={4} xl={4}>
                        <TextField
                            type="text"
                            id="adornment-search"
                            label="Keyword Search"
                            name="search"
                            placeholder="Search Transactions by Name, User ID"
                            onChange={(e) => storeChange(e, 'search')}
                            onKeyPress={props.handleKeyPressSearch}
                            // value={this.state.search}
                            // variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            className='icon'
                                            aria-label="Toggle password visibility"
                                            size='large'
                                            // onClick={this.handleClickSearch}
                                        >
                                            <i className="fas fa-search fa-xs"> </i>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            fullWidth
                        />
                    </Grid>}
                    <Grid item md={3} lg={2} xl={2} direction='column' alignContent='center' alignItems={props.userID ? 'start' : 'center'} justify='start' style={{textAlign: "start", display: "flex"}}>
                        <TextField
                            id="start-date"
                            label="Start Date"
                            type="date"
                            // defaultValue="2021-01-01"
                            className="search-start-date"
                            onChange={(e) => storeChange(e, 'startDate')}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            style={{marginRight: '10px'}}
                        />
                    </Grid>
                    <Grid item md={3} lg={2} direction='column' alignContent='center' alignItems={props.userID ? 'start' : 'center'} justify='center' style={{textAlign: "center", display: "flex"}}>
                        <TextField
                            id="end-date"
                            label="End Date"
                            type="date"
                            className="search-end-date"
                            onChange={(e) => storeChange(e, 'endDate')}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                    </Grid>
                    {!props.userID && <Grid item md={3} lg={2}>
                        <FormControl className='sd' direction='column' alignContent='center' alignItems={props.userID ? 'start' : 'center'} justify='center' style={{textAlign: "center", display: "flex", minWidth: 120}}>
                            <InputLabel id="demo-simple-select-label">Transaction Type</InputLabel>
                            <Select
                            lg={2}
                            labelId="demo-simple-select-label"
                            id="transaction-type-select"
                            // variant='outlined'
                            value={typeInput}
                            onChange={(e) => storeChange(e, "type")}
                            >
                            <MenuItem value={''}>All</MenuItem>
                            <MenuItem value={'toll'}>Toll</MenuItem>
                            <MenuItem value={'topup'}>Top Up</MenuItem>
                            <MenuItem value={'sub'}>Subscription</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>}
                    {/* <Grid item md={3} lg={2}>
                        <FormControlLabel
                            control={
                            <Switch
                                checked={checkedState}
                                onChange={(e) => storeChange(e, "check")}
                                name="togglepromostats"
                                color="primary"
                            />
                            }
                            label="Primary"
                        />
                    </Grid> */}
                </Grid>

            <div className="col-md-3 search-bar" style={{marginTop:'10px'}}>
               

            </div>
            <div className='col-md-3' style={{marginTop: '10px'}}>

            </div>
            </Container>

    )
} 
export default SearchInput;